<template>
  <div class="auth__block">
    <h1 class="title-h2 auth__title">
      <i18n path="main.sign_in_to_account">
        <br />
      </i18n>
    </h1>
    <!-- {{ $route.name }} -->
    <div class="auth__subtitle">
      <div v-html="$t(`${getRouteNameSutbtitleText}`)"></div>
    </div>
    <!-- {{ $t('main.sign_in_to_tour_operator_text') }} -->
    <EuKey />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EuKey from '@/elements/euKey/EuKey.vue'
export default {
  components: { EuKey },
  data() {
    return {}
  },

  created() {
    this.$store.commit('SET_CURRENT_FORM_TYPE', 'auth')
  },

  computed: {
    getRouteNameSutbtitleText() {
      return this.$route.name === 'auth-key-tour-operator'
        ? 'main.sign_in_to_tour_operator_text1'
        : 'main.sign_in_to_tour_operator_text2'
    },
    // ...mapGetters(['getCurrentFormType']),
  },
}
</script>

<style lang="sass">
.auth__subtitle
  font-size: 16px
  line-height: 1.4
  // opacity: 0.9
  margin-bottom: 5rem
</style>

